<template>
  <section class="main-special-block ui-container">
    <div
      v-if="isSlider"
      class="main-special-block__wrapper"
    >
      <div class="main-special-block__slider-wrapper">
        <Swiper
          :modules="[SwiperPagination, SwiperNavigation, SwiperAutoplay]"
          slides-per-view="auto"
          rewind
          :autoplay="isAutoPlayEnabled"
          :pagination="{
            type: 'fraction',
          }"
          :navigation="true"
          class="main-special-block__slider"
        >
          <SwiperSlide
            v-for="(banner, i) in banners"
            :key="i"
            class="main-special-block__item"
          >
            <MainSpecial
              :banner="banner"
              class="main-special-block__banner"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>

    <MainSpecial
      v-else-if="banners?.[0]"
      :banner="banners[0]"
      class="main-special-block__banner main-special-block__banner--single"
    />
  </section>
</template>

<script setup lang="ts">
  import type { MainBanner } from '@academica-box/types'
  import MainSpecial from '@/components/Main/MainSpecialBlock/MainSpecial.vue'

  const props = defineProps<{
    banners?: MainBanner[];
  }>()

  const isAutoPlayEnabled = computed(() => {
    return props.banners && props.banners.length >= 3
      ? {
        delay: 5000,
        disableOnInteraction: false
      }
      : false
  })

  const isSlider = computed(() => {
    return props.banners && props.banners.length > 1
  })
</script>

<style lang="scss">
  @use '@academica-box/styles/UiContainer/mixin' as *;
  @use '@academica-box/utils/styles/mixins/UiMedia/index' as *;

  .main-special-block__wrapper {
    @include media(lg) {
      margin-top: -12px; // для поведения по ховеру
    }
  }

  .main-special-block__slider {
    @include ui-container(lg);

    overflow: visible;

    @include media(sm-max) {
      margin-right: 6px;
      margin-left: 6px;
    }

    @include media(lg) {
      padding-top: 12px; // для поведения по ховеру
    }
  }

  .main-special-block__item {
    height: auto;

    @include media(sm-max) {
      width: calc(100% - 16px);
    }

    &:not(:last-child) {
      margin-right: 6px;

      @include media(lg) {
        margin-right: 16px;
      }

      @include media(xl) {
        margin-right: 32px;
      }
    }
  }

  .main-special-block__banner {
    height: 100%;

    &--single {
      @include ui-container(lg);

      justify-content: center;

      @include media(md-max) {
        margin-right: 6px;
        margin-left: 6px;
      }
    }
  }
</style>
