<template>
  <section class="main-learn">
    <div class="main-lear__wrapper">
      <h2 class="main-learn__title h h--2">
        На курсах <br>вы узнаете:
      </h2>

      <div class="main-learn__video-wrapper">
        <UiVideo
          v-if="activeListItem?.video"
          ref="previewVideo"
          :data="activeListItem.video"
          :loop="false"
          width="280"
          height="157"
          muted
          playsinline
          class="main-learn__video"
        />
      </div>

      <ul class="main-learn__buttons">
        <li
          v-for="(item, i) in list"
          :key="i"
          class="main-learn__button-item"
        >
          <button
            ref="previewButton"
            :class="{'main-learn__button--active': activeContent === i}"
            class="main-learn__button"
            @click="clickHandler(i)"
          >
            <span
              class="main-learn__button-text t t--3"
              v-html="item.menuTitle"
            />
          </button>
        </li>
      </ul>

      <div
        v-if="activeListItem?.course"
        class="main-learn__card"
      >
        <UiInstituteTag
          :tag="activeListItem.course.provider"
          size="big"
          class="main-learn__tag"
        />

        <span
          v-if="activeListItem.course.duration"
          class="main-learn__duration f f--2"
        >
          · {{ activeListItem.course.duration }}
        </span>

        <div
          ref="previewTitleContainer"
          class="main-learn__subtitle"
        >
          <strong
            ref="previewTitle"
            class="t t--2"
            v-html="activeListItem.course.title"
          />
        </div>

        <UiButtonLink
          :link="activeListItem.course.link"
          theme="quinary"
          stretch-mode="md-max"
          size="extra-big"
          class="main-learn__link"
        >
          Начать курс
        </UiButtonLink>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
  import { nextTick, ref, type VNodeRef } from 'vue'
  import UiVideo from '@academica-box/components/Media/UiVideo/UiVideo.vue'
  import UiInstituteTag from '@academica-box/components/Plaques/UiInstituteTag/UiInstituteTag.vue'
  import UiButtonLink from '@academica-box/components/Links/UiButtonLink/UiButtonLink.vue'
  import type { MainLearn } from '../../types/main'

  const props = defineProps<{
    list: MainLearn[];
  }>()

  const activeContent = ref(0)
  const previewButton = ref<HTMLElement[] | null>(null)
  const previewVideo = ref<VNodeRef | null>(null)
  const previewTitle = ref<HTMLElement | null>(null)
  const previewTitleContainer = ref<HTMLElement | null>(null)
  const oldHeight = ref(0)
  const newHeight = ref(0)

  onMounted(() => {
    nextTick(() => {
      const video = previewVideo.value.$el.querySelector('video')

      video.addEventListener('timeupdate', updateProgressBar)
      video.addEventListener('ended', showNextPreview)

      if (previewTitle.value && previewTitleContainer.value) {
        oldHeight.value = previewTitle.value.getBoundingClientRect().height || 0
        previewTitleContainer.value.style.height = `${oldHeight.value}px`
      }
    })
  })

  const activeListItem = computed(() => {
    return props.list[activeContent.value]
  })

  const animateTitleHeight = () => {
    newHeight.value = previewTitle.value?.getBoundingClientRect().height || 0

    if (previewTitleContainer.value && newHeight.value !== oldHeight.value) {
      previewTitleContainer.value.style.height = `${newHeight.value}px`
    }

    oldHeight.value = newHeight.value
  }

  const showNextPreview = (evt: Event) => {
    const video = evt.target as HTMLVideoElement

    if (activeContent.value < props.list.length - 1) {
      activeContent.value++
    } else {
      activeContent.value = 0
    }

    video.load()
    video.play()

    setTimeout(animateTitleHeight, 100)
  }

  const updateProgressBar = (evt: Event) => {
    const video = evt.target as HTMLVideoElement
    const parent = video.parentElement
    let percent = 0

    if (!isNaN(video.duration)) {
      percent = video.currentTime * 100 / video.duration
    }

    if (parent) {
      // @ts-ignore
      parent.style = `--progress: ${percent.toFixed(3)}%;`
    }
  }

  const clickHandler = (id: number) => {
    const video = previewVideo.value.$el.querySelector('video')

    activeContent.value = id

    video.load()
    video.play()

    setTimeout(animateTitleHeight, 100)
  }
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins' as *;
  @use '@academica-box/styles/UiContainer/mixin' as *;

  .main-lear__wrapper {
    @include ui-container(lg);

    margin-right: 6px;
    margin-left: 6px;
    padding: 48px 14px 32px;
    border-radius: 12px;
    background-color: var(--bg-secondary-color);

    @include media(md) {
      padding-right: 22px;
      padding-left: 22px;
    }

    @include media(lg) {
      display: grid;
      grid-column-gap: 64px;
      grid-template-rows: auto auto 1fr;
      grid-template-columns: 338px 1fr;
      padding-right: 48px;
      padding-bottom: 48px;
      padding-left: 64px;
    }

    @include media(xl) {
      grid-template-columns: 434px 1fr;
    }

    @include media(xxl) {
      grid-template-columns: 576px 1fr;
    }
  }

  .main-learn__title {
    margin-bottom: 32px;

    @include media(lg) {
      grid-row: 1/2;
      grid-column: 1/2;
      width: 280px;
      margin-bottom: 40px;
    }

    @include media(xl) {
      margin-top: 14px;
    }

    @include media(xxl) {
      margin-top: 24px;
    }

    br {
      @include media(md, md-max) {
        display: none;
      }
    }
  }

  .main-learn__video-wrapper {
    @include media(sm-max) {
      position: relative;
      padding-top: calc(100% / 16 * 9);
    }

    @include media(md, md-max) {
      height: 392px;
    }

    @include media(lg) {
      grid-row: 1/3;
      grid-column: 2/-1;
      height: 234px;
      margin-top: 6px;
      border-radius: 12px 12px 0 0;
      overflow: hidden;
      background-color: var(--bg-main-color);
    }

    @include media(xl) {
      height: 232px;
      margin-top: 0;
    }

    @include media(xxl) {
      height: 344px;
    }
  }

  .main-learn__video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    transform: translateZ(0); // фикс уголков для сафари

    @include media(sm-max) {
      position: absolute;
      top: 0;
      left: 0;
    }

    @include media(lg) {
      border-radius: 8px;
    }

    &::before,
    &::after {
      @include z-index(absolute, base, raised);

      bottom: 0;
      left: 0;
      display: block;
      height: 4px;
      content: '';
    }

    &::before {
      width: 100%;
      background-color: var(--bg-main-color);
    }

    &::after {
      width: var(--progress);
      background-color: var(--accent-brand-color);
    }
  }

  .main-learn__buttons {
    @include ulres;

    margin: 0 -14px;
    padding: 16px 0 32px 14px; // для тени

    @include media(md) {
      margin-right: -22px;
      margin-left: -22px;
      padding-left: 22px;
    }

    @include media(md-max) {
      display: flex;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    @include media(lg) {
      grid-row: 2/-1;
      grid-column: 1/2;
      margin: 0;
      padding: 0;
    }

    // фикс для Сафари
    &::after {
      padding-right: 14px;

      @include media(md) {
        padding-right: 22px;
      }

      @include media(md-max) {
        content: '';
      }
    }

    &::-webkit-scrollbar {
      @include media(md-max) {
        display: none;
      }
    }
  }

  .main-learn__button-item {
    &:not(:last-child) {
      @include media(md-max) {
        margin-right: 6px;
      }

      @include media(lg) {
        margin-bottom: 6px;
      }
    }
  }

  .main-learn__button:focus-visible {
    @include ui-shadow-8pt;
  }

  /* stylelint-disable */
  .main-learn__button {
    @include inpres;
    @include ui-motion(all button-highlight-main);

    width: max-content;
    padding: 20px 24px;
    border-radius: 10px;
    color: var(--text-secondary-color);
    text-align: left;
    line-height: 0;
    background-color: var(--bg-with-shadow-color);
    opacity: .6;
    cursor: pointer;

    @include media(lg) {
      position: relative;
      width: 100%;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    &::before {
      @include media(lg) {
        @include ui-motion(all button-click-main);

        position: absolute;
        top: 16px;
        left: 24px;
        display: block;
        width: 4px;
        height: calc(100% - 32px);
        border-radius: 900px;
        background-color: var(--accent-brand-color);
        opacity: 0;
        content: '';
      }
    }

    &--active {
      @include ui-shadow-8pt;

      color: var(--text-main-color);
      background-color: var(--bg-with-shadow-color);
      opacity: 1;

      @include media(lg) {
        position: relative;
        display: flex;
      }

      &::before {
        @include media(lg) {
          opacity: 1;
        }
      }
    }

    @include hover {
      @include ui-shadow-8pt;
    }

    br {
      @include media(lg) {
        display: none;
      }
    }
  }

  .is-safari .main-learn__button:focus {
    @include ui-shadow-8pt;
  }
  /* stylelint-enable */

  .main-learn__button-text {
    @include ui-motion(all button-click-main);
  }

  .main-learn__button--active .main-learn__button-text {
    @include media(lg) {
      width: calc(100% - 16px);
      transform: translateX(16px);
    }
  }

  .main-learn__card {
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: auto 1fr;

    @include media(lg) {
      grid-row: 3/-1;
      grid-column: 2/-1;
      align-self: flex-start;
      padding: 20px 24px 24px;
      border-radius: 0 0 12px 12px;
      background-color: var(--bg-main-color);
    }

    @include media(xxl) {
      grid-template-rows: auto 1fr;
      grid-template-columns: auto 1fr auto;
    }
  }

  .main-learn__tag {
    grid-row: 1/2;
    grid-column: 1/2;

    // TODO: когда будет задачка на правку компонента в ДС, выпилить хардкод тут
    .ab-ui-institute-tag__picture {
      width: 28px;
      height: 28px;
    }
  }

  .main-learn__duration {
    display: inline-block;
    grid-row: 1/2;
    grid-column: 2/3;
    align-self: center;
    margin-left: 6px;
    color: var(--text-secondary-color);
  }

  .main-learn__subtitle {
    @include ui-motion(height spoiler-expand-main);

    grid-row: 2/3;
    grid-column: 1/-1;
    margin-top: 8px;
    margin-bottom: 24px;
    overflow: hidden;

    @include media(lg) {
      margin-top: 12px;
      margin-bottom: 31px;
    }

    @include media(xxl) {
      grid-column: 1/3;
      margin-right: 50px;
      margin-bottom: 0;
    }
  }

  .main-learn__link {
    grid-row: 3/-1;
    grid-column: 1/-1;

    @include media(lg, xl-max) {
      justify-self: flex-start;
    }

    @include media(xxl) {
      grid-row: 2/-1;
      grid-column: 3/-1;
      align-self: flex-start;
    }
  }
</style>
