<template>
  <main class="main-page">
    <MainStartScreen
      ref="startScreen"
      :title="startScreenData.title"
      :description="startScreenData.description"
      :link="user ? startScreenData.link : '/login/'"
      :video-mobile="startScreenData.videoMobile"
      :video-desktop="startScreenData.videoDesktop"
      class="main-page__block main-page__start-screen"
    />

    <SliderLogo
      v-if="partnersCarousel"
      description="При поддержке:"
      class="main-page__block main-page__slider-logo"
      :items="partnersCarousel"
    />

    <div class="main-page__block main-page__block--shadow">
      <ShelfNewCourses />
    </div>

    <div class="main-page__block main-page__block--shadow">
      <ShelfCoursesUnder1000 />
    </div>

    <div class="main-page__block main-page__block--shadow">
      <ShelfFreeCourses />
    </div>

    <MainSpecialBlock
      v-if="banners"
      :banners="banners"
      class="main-page__block main-page__block--sm-max-stretch"
    />

    <MainLearn
      :list="previewsListData"
      class="main-page__block"
    />

    <div class="main-page__block main-page__block--shadow">
      <ShelfUniversityCourses />
    </div>

    <MainForWhoBlock
      :title="forWhoBlockData.title"
      :images="forWhoBlockData.images"
      :items="forWhoBlockData.items"
      :link="user ? forWhoBlockData.link : '/login/'"
      class="main-page__block main-page__block--grey"
    />

    <div class="main-page__block main-page__block--shadow">
      <ShelfFastStart />
    </div>

    <div class="main-page__block main-page__block--shadow">
      <MainAuthors
        v-if="authorsBlock"
        :title="authorsBlock.title"
        :description="authorsBlock.description"
        :authors="authorsBlock.items"
      />
    </div>

    <MainVideoBlock
      title="Учитесь с&nbsp;комфортом из&nbsp;любой точки мира"
      :videos="videoBlockData"
      class="main-page__block main-page__block--grey ui-container"
    />

    <MainTopics
      class="main-page__block ui-container"
    />

    <MainCertificate class="main-page__block main-page__block--grey ui-container" />

    <MainReviews
      v-if="reviews"
      :reviews="reviews"
      class="main-page__block"
    />

    <MainTilesBlock
      :images="tilesBlockData.images"
      :slides="tilesBlockData.slides"
      class="main-page__block main-page__block--md-max-stretch ui-container"
    />

    <FaqBlock
      v-if="faqBlock"
      ref="faq"
      :title="faqBlock.title"
      :faq="faqBlock.items"
      class="main-page__block"
    />

    <MainBar
      v-if="!user"
      :class="{'main-page__bar--visible': isVisibleBar}"
      class="main-page__bar"
    />
  </main>
</template>

<script setup lang="ts">
  import { ref, onMounted, type VNodeRef } from 'vue'
  import { storeToRefs } from 'pinia'

  import { useUserStore } from '@academica-box/composables/store/user'
  import { usePromoStore } from '@academica-box/composables/store/promo'
  import { useSubscribesStore } from '@academica-box/composables/store/subscribes'
  import { subscribeProMock } from '@academica-box/mocks'
  import type { MainBanner } from '@academica-box/types'
  import {
    startScreenData,
    forWhoBlockData,
    videoBlockData,
    previewsListData,
    tilesBlockData
  } from '@/pages/mocks'

  import { usePartnersStore } from '@/store/partners'
  import { useReviewsStore } from '@/store/reviews'
  import { useMainStore } from '@/store/main'

  import MainStartScreen from '@/components/Main/MainStartScreen.vue'
  import MainSpecialBlock from '@/components/Main/MainSpecialBlock/MainSpecialBlock.vue'
  import MainAuthors from '@/components/Main/MainAuthors.vue'
  import MainTopics from '@/components/Main/MainTopics.vue'
  import MainBar from '@/components/Main/MainBar.vue'
  import MainTilesBlock from '@/components/Main/MainTilesBlock/MainTilesBlock.vue'
  import MainReviews from '@/components/Main/MainReviews.vue'
  import MainForWhoBlock from '@/components/Main/MainForWhoBlock.vue'
  import MainLearn from '@/components/Main/MainLearn.vue'

  import ShelfFreeCourses from '@/pages/index/components/ShelfFreeCourses.vue'
  import ShelfCoursesUnder1000 from '@/pages/index/components/ShelfCoursesUnder1000.vue'
  import ShelfNewCourses from '@/pages/index/components/ShelfNewCourses.vue'
  import ShelfUniversityCourses from '@/pages/index/components/ShelfUniversityCourses.vue'
  import ShelfFastStart from '@/pages/index/components/ShelfFastStart.vue'

  const mainStore = useMainStore()
  const { authorsBlock, faqBlock } = storeToRefs(mainStore)
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)
  const partnersStore = usePartnersStore()
  const { carousel: partnersCarousel } = storeToRefs(partnersStore)
  const reviewsStore = useReviewsStore()
  const { reviews } = storeToRefs(reviewsStore)
  const promoStore = usePromoStore()
  const { promo } = storeToRefs(promoStore)
  const subscribesStore = useSubscribesStore()
  const { isSubscriptionAvailable, isUserSubscriptionActive, roundedSubscriptionCoursesCount } = storeToRefs(subscribesStore)

  useAsyncData(
    'getPartnersCarousel',
    () => partnersStore.getPartnersCarousel()
  )

  useAsyncData(
    'getMainPage',
    () => mainStore.getMainPage()
  )

  useAsyncData(
    'getReviews',
    () => reviewsStore.getReviews({
      limit: 12,
      isVisibleOnMainPage: 1,
      entityType: 'academica_landings',
    })
  )

  useSeoMeta({
    title: 'Онлайн-курсы от ведущих экспертов | Академика',
    description: 'Образовательная платформа «Академика» — более 200 онлайн-курсов от известных вузов и компаний. Сотрудничаем с МФТИ, НИУ ВШЭ, МГИМО, НГУ, РУДН, НИТУ МИСИС, БФУ и другими университетами. Начните учиться бесплатно прямо сейчас.',
    // OpenGraph
    ogUrl: 'https://academika.ru/',
    ogType: 'website',
    ogTitle: 'Онлайн-курсы от ведущих экспертов | Академика',
    ogDescription: 'Образовательная платформа «Академика» — более 200 онлайн-курсов от известных вузов и компаний. Сотрудничаем с МФТИ, НИУ ВШЭ, МГИМО, НГУ, РУДН, НИТУ МИСИС, БФУ и другими университетами. Начните учиться бесплатно прямо сейчас.',
    ogImage: 'https://academika.ru/og.jpg',
    // Twitter OpenGraph
    twitterCard: 'summary_large_image',
    twitterTitle: 'Онлайн-курсы от ведущих экспертов | Академика',
    twitterDescription: 'Образовательная платформа «Академика» — более 200 онлайн-курсов от известных вузов и компаний. Сотрудничаем с МФТИ, НИУ ВШЭ, МГИМО, НГУ, РУДН, НИТУ МИСИС, БФУ и другими университетами. Начните учиться бесплатно прямо сейчас.',
    twitterImage: 'https://academika.ru/og.jpg',
  })

  const banners: MainBanner[] = []

  if (isSubscriptionAvailable.value && subscribeProMock()?.banners?.page) {
    const subscriptionPageBanner = subscribeProMock({
      subscriptionCoursesCount: roundedSubscriptionCoursesCount.value,
      isUserSubscriptionActive: isUserSubscriptionActive.value
    })?.banners?.page as MainBanner
    banners.unshift({
      ...subscriptionPageBanner
    })
  }

  if (promo.value?.main_banner) {
    banners.unshift({
      ...promo.value.main_banner,
      terms_link: promo.value.terms_link,
    } as MainBanner)
  }

  const isVisibleBar = ref(false)
  const startScreen = ref<VNodeRef | null>(null)
  const faq = ref<VNodeRef | null>(null)
  const observer = ref<IntersectionObserver | null>(null)

  const changeVisibleBar = ([entry]: IntersectionObserverEntry[]) => {
    isVisibleBar.value = !entry?.isIntersecting
  }

  const hideBarHandler = () => {
    const pageHeight = document.documentElement.offsetHeight
    const footerHeight = document.querySelector('footer')?.clientHeight || 150
    const scroll = document.documentElement.scrollTop + document.documentElement.clientHeight + footerHeight

    if (scroll >= pageHeight) {
      isVisibleBar.value = false
    }
  }

  onMounted(() => {
    observer.value = new IntersectionObserver(changeVisibleBar)

    startScreen?.value?.$el && observer.value?.observe(startScreen.value.$el)
    faq?.value?.$el && observer.value?.observe(faq.value.$el)

    window.addEventListener('scroll', hideBarHandler)
  })

  onUnmounted(() => {
    startScreen?.value?.$el && observer.value?.unobserve(startScreen.value.$el)
    faq?.value?.$el && observer.value?.unobserve(faq.value.$el)

    window.removeEventListener('scroll', hideBarHandler)
  })
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiMedia' as *;
  @use '@academica-box/utils/styles/mixins/UiZIndex' as *;
  @use '@academica-box/styles/UiContainer/variables' as *;

  .main-page {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  .main-page__block {
    margin-top: 28px;
    margin-bottom: 28px;

    @include media(lg) {
      margin-top: 56px;
      margin-bottom: 56px;
    }

    &:last-of-type {
      margin-bottom: 56px;

      @include media(lg) {
        margin-bottom: 104px;
      }
    }

    &--stretch {
      width: calc(100% - 12px);
      max-width: calc(1600px - 64px);
      margin-right: auto;
      margin-left: auto;

      @include media(lg) {
        width: calc(100% - 32px);
      }

      @include media(xl) {
        width: calc(100% - 64px);
      }

      @include media(xxl) {
        width: calc(100% - 12px);
      }
    }

    &--shadow {
      position: relative;

      @include media(md-max) {
        margin-bottom: 0;
        padding-bottom: 28px;
      }

      &::before {
        @include z-index(below);

        position: absolute;
        top: 45%;
        z-index: -1;
        width: 100%;
        height: 50%;
        border-radius: 30%;
        background-color: var(--bg-invert-color);
        opacity: 0.02;
        filter: blur(25px);
        content: "";
      }
    }

    &--grey {
      @include media(md-max) {
        width: calc(100% - 12px);
        margin-right: auto;
        margin-left: auto;
        padding-right: 14px;
        padding-left: 14px;
      }
    }

    &--sm-max-stretch {
      @include media(sm-max) {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
    }

    &--md-max-stretch {
      @include media(md-max) {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .main-page__start-screen {
    margin-top: 0;

    @include media(lg) {
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }

  .main-page__slider-logo {
    margin-top: 40px;

    @include media(lg) {
      margin-bottom: 40px;
    }

    @include media(1600) {
      margin-top: 0;
      margin-bottom: 72px;
    }
  }

  .main-page__bar {
    @include z-index(fixed, elevated);

    bottom: 64px;
    left: 50%;
    width: calc(100% - 12px);
    margin-left: calc(-50% + 6px);
    transform: translateY(200%);
    transition: transform 0.2s ease-in-out;
    will-change: transform;

    @include media(md) {
      width: $ui-md-c;
      margin-left: calc(-#{$ui-md-c} / 2);
    }

    @include media(lg) {
      bottom: 12px;
      width: $ui-lg-c;
      margin-left: calc(-#{$ui-lg-c} / 2);
    }

    &--visible {
      transform: translateY(0);
    }
  }
</style>
