<template>
  <section class="main-certificate">
    <div class="main-certificate__wrapper">
      <h2
        class="main-certificate__title h"
        :class="!isMobile ? 'h--4' : 'h--3'"
      >
        Сертификат подтвердит ваши навыки
      </h2>

      <UiPicture
        :data="image"
        width="280"
        height="196"
        class="main-certificate__picture ui-shadow-15pt"
      />

      <ul class="main-certificate__list">
        <li
          v-for="(advantage, i) in advantages"
          :key="i"
          class="main-certificate__item"
        >
          <svg-icon
            name="basic/ui-done"
            width="24"
            height="24"
            class="main-certificate__icon"
            aria-hidden="true"
          />

          <span class="main-certificate__advantages p p--3" v-html="advantage" />
        </li>
      </ul>

      <UiButton
        theme="quaternary"
        size="extra-big"
        stretch-mode="md-max"
        class="main-certificate__btn"
        @click="isShowModal = true"
      >
        Рассмотреть поближе
      </UiButton>
    </div>

    <UiDetailsModal
      v-if="isShowModal"
      @click:close="isShowModal = false"
    >
      <UiPicture
        :data="image"
        :is-cover="false"
        width="280"
        height="196"
      />
    </UiDetailsModal>
  </section>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import { getUiPictureData } from '@academica-box/helpers/getUiPictureData'
  import UiPicture from '@academica-box/components/Media/UiPicture/UiPicture.vue'
  import UiButton from '@academica-box/components/Buttons/UiButton/UiButton.vue'
  import UiDetailsModal from '@academica-box/components/Modals/UiDetailsModal/UiDetailsModal.vue'

  const viewport = useViewport()
  const isMobile = computed(() => viewport.isLessThan('lg'))

  const isShowModal = ref(false)
  const image = ref(getUiPictureData({ sm: ['https://cdn.skillbox.pro/wbd-front/academica-static/certificates/certificate-empty.svg'] }))

  const advantages = [
    'Покажет, что вы&nbsp;прошли курс от&nbsp;известного вуза или крупной компании',
    'Усилит портфолио или резюме и&nbsp;выделит вас среди других кандидатов',
    'Достоверность можно проверить по&nbsp;QR-коду в&nbsp;любой момент'
  ]
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiReset' as *;
  @use '@academica-box/utils/styles/mixins/UiMedia' as *;

  .main-certificate {
    display: flex;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--bg-secondary-color);
  }

  .main-certificate__wrapper {
    display: grid;
    grid-row-gap: 40px;
    grid-template-columns: 1fr;
    padding-top: 48px;
    padding-bottom: 36px;

    @include media(sm-max) {
      justify-items: center;
    }

    @include media(md) {
      grid-column-gap: 64px;
      grid-template-columns:min-content 1fr;
      padding-bottom: 40px;
    }

    @include media(lg) {
      grid-row-gap: 32px;
      max-width: 832px;
      padding-top: 72px;
      padding-bottom: 72px;
    }

    @include media(xl) {
      grid-column-gap: 88px;
      max-width: 920px;
    }

    @include media(xxl) {
      grid-column-gap: 120px;
      max-width: 1200px;
    }
  }

  .main-certificate__title {
    margin: 0;

    @include media(sm-max) {
      text-align: center;
    }

    @include media(md, md-max) {
      grid-row: 1/2;
      grid-column: 2/3;
      align-self: center;
      max-width: 352px;
      padding-right: 45px;
    }

    @include media(lg) {
      grid-column: 2/3;
      max-width: 443px;
    }
  }

  .main-certificate__picture {
    width: 280px;
    height: 196px;

    @include media(md) {
      grid-row: 1/2;
    }

    @include media(lg) {
      grid-row: 1/3;
      width: 368px;
      height: 259px;
    }

    @include media(xl) {
      width: 384px;
      height: 271px;
    }

    @include media(xxl) {
      grid-row: 1/4;
      width: 496px;
      height: 348px;
      margin-top: 3px;
    }
  }

  .main-certificate__list {
    @include ulres;

    @include media(md, md-max) {
      grid-column: 1/-1;
    }

    @include media(lg) {
      grid-column: 2/3;
    }

    @include media(xxl) {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: min-content min-content;
    }
  }

  .main-certificate__item {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: min-content 1fr;

    @include media(lg) {
      padding: 4px 0;
    }

    @include media(xxl) {
      width: 280px;
    }

    &:not(:last-child) {
      @include media(md-max) {
        margin-bottom: 16px;
      }

      @include media(lg-max) {
        margin-bottom: 12px;
      }
    }
  }

  .main-certificate__advantages {
    @include media(md-max) {
      padding-top: 2px;
    }
  }

  .main-certificate__btn {
    @include media(md-max) {
      grid-column: 1/-1;
    }

    @include media(lg) {
      grid-column: 2/3;
      justify-self: flex-start;
    }
  }
</style>
