<template>
  <section class="main-reviews ui-container">
    <header class="main-reviews__header">
      <h2 class="main-reviews__title h h--2">
        С&nbsp;нами больше 100&nbsp;тысяч пользователей
      </h2>

      <p class="main-reviews__text p p--2">
        Ценим обратную связь. Когда вы&nbsp;напишете отзыв, он&nbsp;тоже появится здесь
      </p>

      <div class="main-reviews__rating h h--3">
        <svg-icon
          name="basic/ui-star-filled"
          width="24"
          height="24"
          aria-label="Общий рейтинг:"
          class="main-reviews__star"
        />

        4.4
      </div>
    </header>

    <ul class="main-reviews__list">
      <li
        v-for="(review, i) in slicedReviews"
        :key="i"
        class="main-reviews__item"
      >
        <UiReviewCard
          :user="review.author"
          :review="review.review"
          class="main-reviews__card"
        />
      </li>
    </ul>

    <UiButton
      v-if="reviews.length > 2"
      theme="quaternary"
      size="big"
      stretch
      class="main-reviews__button"
      @click="isExpanded = !isExpanded"
    >
      {{ isExpanded ? 'Скрыть' : 'Показать ещё' }}
    </UiButton>
  </section>
</template>

<script setup lang="ts">
  import UiButton from '@academica-box/components/Buttons/UiButton/UiButton.vue'
  import UiReviewCard from '@academica-box/components/Cards/UiReviewCard/UiReviewCard.vue'
  import type { VendorReview } from '@/types/vendor'

  const props = defineProps<{
    reviews: VendorReview[];
  }>()

  const isExpanded = ref(false)

  const slicedReviews = computed(() => {
    return isExpanded.value
      ? [...props.reviews].splice(0, 10)
      : [...props.reviews].splice(0, 2)
  })
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiReset' as *;
  @use '@academica-box/utils/styles/mixins/UiMedia' as *;

  .main-reviews {
    @include media(lg) {
      display: grid;
      grid-gap: 32px;
      grid-template-rows: auto auto;
      grid-template-columns: 288px 1fr;
    }

    @include media(xl) {
      grid-template-columns: 320px 1fr;
    }

    @include media(xxl) {
      grid-column-gap: 128px;
      grid-template-columns: 400px 1fr;
    }
  }

  .main-reviews__header {
    margin-bottom: 32px;

    @include media(md) {
      margin-bottom: 24px;
    }

    @include media(md, md-max) {
      width: 332px;
    }

    @include media(lg) {
      grid-row: 1/-1;
      grid-column: 1/2;
    }
  }

  .main-reviews__title {
    margin-bottom: 8px;

    // костыли для типографики из-за макета
    @include media(lg) {
      margin-bottom: 12px;
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.38px;
    }
  }

  .main-reviews__text {
    margin-bottom: 24px;
    color: var(--text-secondary-color);
  }

  .main-reviews__rating {
    display: flex;
  }

  .main-reviews__star {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    color: var(--accent-warning-color);

    @include media(lg) {
      width: 40px;
      height: 40px;
    }
  }

  .main-reviews__list {
    @include ulres;

    margin-bottom: 20px;

    @include media(md, md-max) {
      display: grid;
      grid-gap: 6px;
      grid-template-columns: 1fr 1fr;
    }

    @include media(lg) {
      grid-row: 1/2;
      grid-column: 2/-1;
      margin-bottom: 0;
    }

    @include media(xxl) {
      display: grid;
      grid-gap: 6px;
      grid-template-columns: 1fr 1fr;
    }
  }

  .main-reviews__item {
    &:not(:last-child) {
      @include media(sm-max) {
        margin-bottom: 6px;
      }

      @include media(lg, xl-max) {
        margin-bottom: 6px;
      }
    }
  }

  .main-reviews__card {
    @include media(md) {
      height: 100%;
    }
  }

  .main-reviews__button {
    @include media(lg) {
      grid-row: 2/-1;
      grid-column: 2/-1;
    }
  }
</style>
