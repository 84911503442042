<template>
  <section class="main-topics">
    <h2 class="main-topics__title h h--2">
      Больше 250&nbsp;курсов, которые хочется пройти до&nbsp;конца
    </h2>

    <ul class="main-topics__list">
      <li
        v-for="(direction, i) in directions"
        :key="i"
        class="main-topics__item"
      >
        <UiLinkTag
          :link="`/catalog/${direction.link}`"
          theme="secondary"
          size-mode="big-lg"
        >
          {{ direction.title }}
        </UiLinkTag>
      </li>
    </ul>

    <UiLink
      link="/catalog/"
      size="big"
      theme="black"
      class="main-topics__link"
    >
      Перейти в&nbsp;каталог
    </UiLink>
  </section>
</template>

<script setup lang="ts">
  import UiLinkTag from '@academica-box/components/Plaques/UiLinkTag/UiLinkTag.vue'
  import UiLink from '@academica-box/components/Links/UiLink/UiLink.vue'

  const directions = [
    {
      title: 'Тестирование',
      link: 'akd-development/testirovanie/'
    },
    {
      title: 'Обработка и анализ данных',
      link: 'akd-development/akd-data-processing-and-analysis/'
    },
    {
      title: 'Инфраструктура',
      link: 'akd-development/akd-infrastructure/'
    },
    {
      title: 'Графический дизайн и иллюстрация',
      link: 'akd-design/akd-graphic-design-and-illustration/'
    },
    {
      title: 'Интернет-маркетинг',
      link: 'akd-marketing/akd-internet-marketing/'
    },
    {
      title: 'Иностранные языки',
      link: 'akd-education-and-learning/akd-foreign-languages/'
    },
    {
      title: 'Подготовка к экзаменам',
      link: 'akd-education-and-learning/akd-exam-preparation/'
    },
    {
      title: 'Менеджмент',
      link: 'akd-management/menedzhment/'
    },
    {
      title: 'Проекты и продукты ',
      link: 'akd-management/akd-projects-and-products/'
    },
    {
      title: 'Финансовое моделирование и анализ',
      link: 'akd-finance/akd-financial-modeling-and-analysis/'
    },
    {
      title: 'Криптовалюты и блокчейн',
      link: 'akd-finance/akd-cryptocurrencies-and-blockchain/'
    },
    {
      title: 'Инвестирование и трейдинг',
      link: 'akd-finance/akd-investing-and-trading/'
    },
    {
      title: 'Предпринимательство',
      link: 'akd-business/predprinimatelstvo/'
    },
    {
      title: 'Фотография',
      link: 'akd-photography-and-video/photo/'
    },
  ]
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiMedia' as *;
  @use '@academica-box/utils/styles/mixins/UiReset' as *;

  .main-topics {
    text-align: center;
  }

  .main-topics__title {
    max-width: 416px;
    margin-right: auto;
    margin-bottom: 32px;
    margin-left: auto;

    @include media(lg) {
      max-width: 654px;
      margin-bottom: 48px;
    }
  }

  .main-topics__list {
    @include ulres;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -6px;
    margin-bottom: 32px;

    @include media(lg) {
      margin-bottom: 48px;
    }

    @include media(xl) {
      max-width: 1024px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .main-topics__item {
    margin-top: 6px;

    &:not(:last-child) {
      margin-right: 6px;
    }
  }

  .main-topics__link {
    display: block;
  }
</style>
