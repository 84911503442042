<template>
  <div class="main-tile">
    <div class="main-tile__text">
      <h2
        v-if="title"
        :style="{
          '--title-color': `var(--${titleColor})`,
        }"
        class="main-tile__title h"
        :class="['h', !isMobile ? 'h--4' : 'h--3']"
        v-html="title"
      />

      <p
        :style="{
          '--description-color': `var(--${descriptionColor})`,
        }"
        class="main-tile__description p p--2"
      >
        <slot name="description" />
      </p>
    </div>

    <slot />
  </div>
</template>

<script setup lang="ts">
  const viewport = useViewport()

  const isMobile = computed(() => viewport.isLessThan('lg'))

  withDefaults(defineProps<{
    title: string;
    titleColor?: string;
    descriptionColor?: string;
  }>(), {
    titleColor: 'text-main-color',
    descriptionColor: 'text-secondary-color',
  })
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiMedia' as *;

  .main-tile {
    padding-top: 48px;
    padding-bottom: 48px;
    border-radius: 12px;
    text-align: center;

    @include media(lg) {
      padding: 56px 32px;
    }
  }

  .main-tile__text {
    max-width: 318px;
    margin: 0 auto;

    @include media(lg) {
      max-width: 448px;
    }
  }

  .main-tile__title {
    margin-bottom: 12px;
    color: var(--title-color);
  }

  .main-tile__description {
    margin: 0;
    color: var(--description-color);
  }
</style>
