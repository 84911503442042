<template>
  <section class="main-video-block">
    <div class="main-video-block__wrapper">
      <h2 class="main-video-block__title h h--3" v-html="title" />

      <div class="main-video-block__video-wrapper">
        <UiPicture
          :data="images"
          width="288"
          height="190"
          class="main-video-block__picture"
          :is-cover="false"
        />

        <UiVideo
          :data="videos"
          width="230"
          height="149"
          class="main-video-block__video"
        />
      </div>

      <ul class="main-video-block__list">
        <li
          v-for="(advantage ,i) in advantages"
          :key="i"
          class="main-video-block__item"
        >
          <svg-icon
            name="basic/ui-done"
            width="24"
            height="24"
            aria-hidden="true"
            class="main-video-block__item-icon"
          />

          <p class="main-video-block__advantage p p--3">
            <strong>{{ advantage.title }}</strong>

            <span v-html="advantage.description" />
          </p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup lang="ts">
  import { getUiPictureData } from '@academica-box/helpers/getUiPictureData'

  import UiPicture from '@academica-box/components/Media/UiPicture/UiPicture.vue'
  import UiVideo from '@academica-box/components/Media/UiVideo/UiVideo.vue'
  import type { Video } from '@/types'

  defineProps<{
    title: string;
    videos: Video
  }>()

  const images = getUiPictureData({
    sm: [
      'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/laptop-sm@1x.avif',
      'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/laptop-sm@1x.webp',
      'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/laptop-sm@1x.png'
    ],
    lg: [
      'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/laptop-lg@1x.avif',
      'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/laptop-lg@1x.webp',
      'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/laptop-lg@1x.png'
    ],
  })

  const advantages = [
    {
      title: 'Качество.',
      description: 'Выбирайте курсы от&nbsp;авторитетных вузов'
    },
    {
      title: 'Удобство.',
      description: 'Заходите с&nbsp;компьютера, планшета или&nbsp;смартфона'
    },
    {
      title: 'Результат.',
      description: 'Закрепляйте навыки на&nbsp;заданиях с&nbsp;автопроверкой'
    }
  ]
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiReset' as *;
  @use '@academica-box/utils/styles/mixins/UiMedia' as *;

  .main-video-block {
    border-radius: 12px;
    background-color: var(--bg-secondary-color);
  }

  .main-video-block__wrapper {
    padding-top: 48px;
    padding-bottom: 48px;

    @include media(lg) {
      padding-top: 56px;
      padding-bottom: 56px;
    }
  }

  .main-video-block__title {
    margin-bottom: 16px;
    text-align: center;

    @include media(lg) {
      margin-bottom: 24px;
    }
  }

  .main-video-block__video-wrapper {
    position: relative;
    height: calc(100vw / 16 * 9);
    margin: 0 auto 32px;

    @include media(md-max) {
      max-width: 374px;
      max-height: 246px;
    }

    @include media(lg) {
      height: 443px;
      margin-bottom: 56px;
    }
  }

  .main-video-block__picture {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 100%;
    transform: translate(-50%, -50%);

    @include media(lg) {
      width: 672px;
      height: 443px;
    }
  }

  .main-video-block__video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 75%;
    transform: translate(-50%, -50%);

    @include media(lg) {
      width: 537px;
      height: 348px;
    }
  }

  .main-video-block__list {
    @include ulres;

    @include media(md) {
      max-width: 728px;
      margin: 0 auto;
    }

    @include media(lg) {
      display: grid;
      grid-column-gap: 32px;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 768px;
    }

    @include media(xl) {
      max-width: 944px;
    }

    @include media(xxl) {
      max-width: 1024px;
    }
  }

  .main-video-block__item {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: min-content 1fr;
    margin: 0;

    @include media(md-max) {
      max-width: 449px;
    }

    &:not(:last-child) {
      @include media(md-max) {
        margin-bottom: 16px;
      }
    }
  }

  .main-video-block__advantage {
    margin: 0;
    color: var(--text-secondary-color);

    strong {
      margin-right: 4px;
      color: var(--text-main-color);
      font-weight: 450;
    }
  }
</style>
