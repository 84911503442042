<template>
  <section class="main-authors">
    <div class="main-authors__wrapper ui-container">
      <h2 class="main-authors__title h h--3">
        {{ title }}
      </h2>

      <p class="main-authors__desc p p--2">
        {{ description }}
      </p>

      <div class="main-authors__slider-container">
        <Swiper
          v-if="isSlider"
          :modules="[SwiperPagination, SwiperNavigation, SwiperA11y]"
          slides-per-view="auto"
          :slides-per-group="slidesPerGroup"
          :pagination="{
            type: 'fraction',
          }"
          :navigation="true"
          class="main-authors__slider"
        >
          <SwiperSlide
            v-for="(author, i) in authors"
            :key="i"
            class="main-authors__item"
          >
            <UiAuthorCard
              :card="author"
              class="main-authors__card"
            />
          </SwiperSlide>
        </Swiper>

        <ul
          v-else
          class="main-authors__list"
        >
          <li
            v-for="(author, i) in authors"
            :key="i"
            class="main-authors__item"
          >
            <UiAuthorCard
              :card="author"
              class="main-authors__card"
            />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
  import UiAuthorCard from '@academica-box/components/Cards/UiAuthorCard/UiAuthorCard.vue'
  import type { AuthorCard } from '@academica-box/components/Cards/UiAuthorCard/types'

  const props = defineProps<{
    title: string;
    description: string;
    authors: AuthorCard[];
  }>()

  const viewport = useViewport()
  const isTablet = computed(() => viewport.isLessThan('lg') && viewport.isGreaterOrEquals('md'))
  const isDesktop = computed(() => viewport.isLessThan('xxl') && viewport.isGreaterOrEquals('lg'))
  const isDesktopXXL = computed(() => viewport.isGreaterOrEquals('xxl'))

  const mobileMinimumSlides = 1
  const tabletMinimumSlides = 2
  const desktopMinimumSlides = 3
  const desktopXXLMinimumSlides = 4

  const isSlider = computed(() => {
    let minimumSlides = mobileMinimumSlides

    if (isTablet.value && !isDesktop.value) {
      minimumSlides = tabletMinimumSlides
    } else if (isDesktop.value && !isDesktopXXL.value) {
      minimumSlides = desktopMinimumSlides
    } else if (isDesktopXXL.value) {
      minimumSlides = desktopXXLMinimumSlides
    }

    return props.authors.length > minimumSlides
  })

  const slidesPerGroup = computed(() => {
    if (isDesktopXXL.value) {
      return 4
    } else if (isDesktop.value) {
      return 3
    } else {
      return 1
    }
  })
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/combinations/media-hover-reset' as *;
  @use '@academica-box/styles/UiContainer/variables' as *;
  @use '@academica-box/styles/UiContainer/mixin' as *;

  .main-authors {
    overflow: hidden;

    @include media(md-max) {
      padding-top: 28px;
      padding-bottom: 28px;
    }
  }

  .main-authors__title {
    margin-bottom: 12px;
    text-align: center;

    @include media(320, 348) {
      width: 260px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .main-authors__desc {
    margin-bottom: 24px;
    color: var(--text-secondary-color);
    text-align: center;

    @include media(lg) {
      margin-bottom: 40px;
    }
  }

  // TODO: временные костыли для слайдера, унести в ДС, когда доработаем слайдер
  #__nuxt .main-authors__slider {
    @include media(lg) {
      padding-bottom: 44px;
    }

    .swiper-wrapper {
      @include media(lg) {
        margin-bottom: 32px;
      }
    }

    .swiper-slide {
      height: auto;
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-color: var(--bg-main-color);

      @include hover {
        background-color: var(--action-accent-color);

        &::after {
          color: var(--icon-invert-color);
        }
      }
    }

    .swiper-button-prev {
      left: 50%;
      transform: translateX(-100%);
    }

    .swiper-button-next {
      left: 50%;
    }

    .swiper-pagination {
      display: none;
    }
  }

  .main-authors__item {
    flex-shrink: 0;
    width: 260px;

    @include media(lg) {
      width: 288px;
    }

    @include media(xl) {
      width: 320px;
    }

    &:not(:last-child) {
      margin-right: 12px;

      @include media(lg) {
        margin-right: 32px;
      }
    }
  }

  .main-authors__card {
    height: 100%;
  }

  .main-authors__list {
    @include ulres;

    display: flex;
    justify-content: center;
  }
</style>
