<template>
  <MainTile
    title="Нам доверяют ведущие вузы и компании"
    class="universities-partners-tile"
  >
    <div class="universities-partners-tile__slider-wrapper">
      <Swiper
        :modules="[SwiperPagination, SwiperA11y, SwiperAutoplay]"
        :slides-per-view="1"
        loop
        :autoplay="{
          delay: 6000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        }"
        :speed="600"
        :pagination="{
          type: 'bullets',
          bulletElement: 'button',
          clickable: true,
          el: '.universities-partners-tile__bullets',
          bulletClass: 'universities-partners-tile__bullet',
          bulletActiveClass: 'universities-partners-tile__bullet--active'
        }"
        class="universities-partners-tile__slider"
      >
        <SwiperSlide
          v-for="(slide, i) in slides"
          :key="i"
          class="universities-partners-tile__slide"
        >
          <p
            class="universities-partners-tile__slider-desc p p--2"
            v-html="slide.text"
          />

          <ul class="universities-partners-tile__slide-list">
            <li
              v-for="(icon, j) in slide.icons"
              :key="j"
              class="universities-partners-tile__slide-item"
            >
              <UiBaseLink
                :link="`/vendor/${icon}/`"
              >
                <img
                  width="80"
                  height="80"
                  :src="getIcon(icon)"
                  :alt="`Логотип организации «${UniversitiesMap[icon]}»`"
                  class="universities-partners-tile__slide-icon"
                >
              </UiBaseLink>
            </li>
          </ul>
        </SwiperSlide>

        <div class="universities-partners-tile__bullets" />
      </Swiper>
    </div>
  </MainTile>
</template>

<script setup lang="ts">
  import UiBaseLink from '@academica-box/components/Links/UiBaseLink/UiBaseLink.vue'

  import MainTile from '@/components/Main/MainTilesBlock/components/MainTile.vue'

  import { UniversitiesMap } from '@/pages/mocks'

  defineProps< {
    slides: {
      text: string;
      icons: string[];
    }[];
  }>()

  const getIcon = (icon: string) => `https://cdn.akademica.pro/wbd-front/academica-static/main-page/tiles-block/${icon}.svg`
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiReset' as *;
  @use '@academica-box/utils/styles/mixins/UiMedia' as *;

  .universities-partners-tile {
    background-color: var(--bg-secondary-color);
  }

  .universities-partners-tile__slider-wrapper {
    overflow: hidden;
  }

  .universities-partners-tile__slider-desc {
    max-width: 318px;
    margin: 0 auto;
    color: var(--text-secondary-color);

    @include media(lg) {
      max-width: 448px;
    }
  }

  .universities-partners-tile__slider {
    padding-bottom: 0 !important; // TODO: убрать после доработки слайдера
  }

  .universities-partners-tile__slide-list {
    @include ulres;

    display: grid;
    grid-gap: 6px;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    width: fit-content;
    margin: 40px auto;

    @include media(lg) {
      margin-top: 53px;
      margin-bottom: 53px;
    }

    @include media(xl) {
      margin-top: 69px;
      margin-bottom: 69px;
    }
  }

  .universities-partners-tile__slide-icon {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;

    @include media(lg) {
      width: 90px;
      height: 90px;
    }
  }

  .universities-partners-tile__bullets {
    display: flex;
    justify-content: center;
  }

  .universities-partners-tile__bullet {
    @include inpres;
    width: 10px;
    height: 10px;
    margin-right: 12px;
    margin-left: 12px;
    border-radius: 50%;
    background-color: var(--icon-addition-color);
    cursor: pointer;

    &--active {
      background-color: var(--bg-main-color-invert);
    }
  }
</style>
