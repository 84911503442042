import { defineStore } from 'pinia'
import type { GetReviewsParams } from '@academica-box/api/reviews'
import type { VendorReview } from '@/types/vendor'

export const useReviewsStore = defineStore('reviews', {
  state: () => ({
    reviews: undefined,
  } as {
    reviews?: VendorReview[];
  }),

  actions: {
    async getReviews(params?: GetReviewsParams) {
      const { $api, $sentry } = useNuxtApp()

      if (this.reviews) {
        return new Promise((resolve) => {
          return resolve(this.reviews)
        })
      }

      try {
        const result = await $api.reviews.getReviews(params)

        this.reviews = result.data

        return this.reviews
      } catch (error) {
        $sentry.captureException(error)
      }
    },

  },
})
