<template>
  <div
    :style="{'--background-color': banner.background_color}"
    class="main-special"
  >
    <div class="main-special__wrapper">
      <div class="main-special__picture-wrapper">
        <UiPicture
          :data="banner.image"
          :is-cover="false"
          width="244"
          height="244"
          mobile-stretch
          class="main-special__picture"
        />
      </div>

      <div class="main-special__info">
        <div class="main-special__text">
          <span
            v-if="banner.subtitle"
            class="main-special__subtitle p p--3"
            :style="{'color': banner.text_color}"
            v-html="banner.subtitle"
          />

          <h2
            class="main-special__title h h--2"
            :style="{'color': banner.text_color}"
          >
            <span v-html="banner.title" />
          </h2>

          <span
            v-if="banner.description"
            :class="descriptionClasses"
            :style="{'color': banner.description_color}"
            v-html="banner.description"
          />
        </div>

        <div class="main-special__button-wrapper">
          <UiButtonLink
            :link="banner.link"
            :theme="banner.button_theme"
            :right-icon="isDesktop ? 'basic/ui-arrow-right' : ''"
            size="extra-big"
            class="main-special__button"
            @click="$analytics?.push({
              category: CategoryEnum.Home,
              action: ActionsEnum.click_link,
              label: 'special_block',
              context: {
                link: banner.link
              }
            })"
          >
            {{ banner.button_text }}
          </UiButtonLink>

          <UiLink
            v-if="banner.terms_link"
            theme="inverted"
            :link="banner.terms_link"
            target="_blank"
            size="big"
            class="main-special__terms"
            :style="{'color': banner.terms_text_color}"
          >
            {{ banner.terms_text }}
          </UiLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import UiPicture from '@academica-box/components/Media/UiPicture/UiPicture.vue'
  import UiButtonLink from '@academica-box/components/Links/UiButtonLink/UiButtonLink.vue'
  import UiLink from '@academica-box/components/Links/UiLink/UiLink.vue'
  import type { MainBanner } from '@academica-box/types'
  import { ActionsEnum, CategoryEnum } from '@academica-box/analytics/events/index'

  const { $analytics } = useNuxtApp()

  defineProps<{
    banner: MainBanner;
  }>()

  const viewport = useViewport()
  const isDesktop = computed(() => viewport.isGreaterOrEquals('lg'))

  const descriptionClasses = computed(() => {
    return [
      'main-special__description',
      'p p--2'
    ]
  })

</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiMedia/index' as *;
  @use '@academica-box/utils/styles/mixins/combinations/hover' as *;

  .main-special {
    display: flex;
    color: var(--admin-color);
  }

  .main-special__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 14px 24px;

    @include media(md) {
      padding-right: 30px;
      padding-left: 30px;
    }

    @include media(lg) {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 420px 360px;
      align-items: center;
      justify-content: space-between;
      padding: 40px 34px 40px 64px;
    }

    @include media(xl) {
      padding-right: 64px;
    }

    @include media(xxl) {
      grid-template-columns: 672px 360px;
      padding-right: 80px;
      padding-left: 80px;
    }

    &::before {
      @include ui-motion(all card-highlight-secondary);

      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background: var(--background-color);
      content: '';
    }

    @include hover {
      &::before {
        transform: scale(1.025);
      }
    }

    &:focus-within {
      @include media(lg) {
        &::before {
          transform: scale(1.025);
        }
      }
    }
  }

  .main-special__picture-wrapper {
    @include media(sm-max) {
      margin-right: 2px;
      margin-bottom: 24px;
      margin-left: 2px;
    }

    @include media(md, md-max) {
      margin: 0 auto 24px;
    }

    @include media(lg) {
      grid-row: 1/-1;
      grid-column: 2/-1;
      align-self: center;
    }
  }

  .main-special__picture {
    @include media(sm-max) {
      position: relative;
      padding-top: calc(100% / 2 * 2);
    }

    @include media(md, md-max) {
      width: 338px;
      height: 338px;
    }
  }

  .main-special__info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include media(md-max) {
      flex-grow: 1;
    }
  }

  .main-special__subtitle {
    display: block;
    margin-bottom: 12px;

    @include media(sm-max) {
      max-width: 260px;
    }

    @include media(lg) {
      margin-bottom: 24px;
    }
  }

  .main-special__title {
    margin-bottom: 30px;

    @include media(lg) {
      margin-bottom: 48px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;

      @include media(lg) {
        margin-bottom: 12px;
      }
    }
  }

  .main-special__description {
    display: block;
    margin-bottom: 24px;
    color: var(--text-secondary-color);

    @include media(lg) {
      margin-bottom: 32px;
    }

    @include media(xl, xl-max) {
      max-width: 475px;
    }
  }

  .main-special__button-wrapper {
    display: flex;

    @include  media(md-max) {
      flex-direction: column;
      text-align: center;
    }
    @include media(lg) {
      align-items: center;
    }
  }

  a.main-special__button {
    @include media(md-max) {
      margin-bottom: 44px;
    }

    &:not(:last-child) {
      @include media(md-max) {
        margin-bottom: 24px;
      }

      @include media(lg) {
        margin-right: 32px;
      }
    }
  }

  .main-special__terms {
    color: var(--text-secondary-color);
  }
</style>
