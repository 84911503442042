<template>
  <section class="main-start-screen">
    <Vue3Marquee
      v-if="isMobile && marqueeText"
      clone
      class="main-start-screen__marquee"
    >
      <svg-icon
        name="basic/ui-flash"
        width="20"
        height="20"
        aria-hidden="true"
        class="main-start-screen__marquee-icon"
      />

      <span
        class="main-start-screen__marquee-text f f--1"
        v-html="marqueeText"
      />
    </Vue3Marquee>

    <div class="main-start-screen__content ui-container">
      <span
        v-if="isDesktop && marqueeText"
        class="main-start-screen__marquee main-start-screen__marquee--tag"
      >
        <svg-icon
          name="basic/ui-flash"
          width="20"
          height="20"
          aria-hidden="true"
          class="main-start-screen__marquee-icon"
        />

        <span
          class="main-start-screen__marquee-text f f--1"
          v-html="marqueeText"
        />
      </span>

      <div class="main-start-screen__text">
        <h1
          class="main-start-screen__title h h--1 h--1-s"
          v-html="title"
        />

        <p
          class="main-start-screen__description p p--2"
          v-html="description"
        />
      </div>

      <UiVideo
        :key="$theme.currentTheme.value"
        :data="computedVideoData"
        width="280"
        height="218"
        class="main-start-screen__video"
      />

      <UiButtonLink
        :link="link"
        theme="quinary"
        stretch-mode="md-max"
        size="giant"
        class="main-start-screen__link"
        @click="$analytics?.push({
          category: CategoryEnum.Home,
          action: ActionsEnum.click_login_button,
        })"
      >
        Начать учиться бесплатно
      </UiButtonLink>
    </div>
  </section>
</template>

<script setup lang="ts">
  import { Vue3Marquee } from 'vue3-marquee'

  import UiVideo from '@academica-box/components/Media/UiVideo/UiVideo.vue'
  import UiButtonLink from '@academica-box/components/Links/UiButtonLink/UiButtonLink.vue'
  import { ActionsEnum, CategoryEnum } from '@academica-box/analytics/events/index'
  import type { Video } from '@/types'

  const { $analytics } = useNuxtApp()
  const viewport = useViewport()
  const isMobile = computed(() => viewport.isLessThan('lg'))
  const isDesktop = computed(() => viewport.isGreaterOrEquals('lg'))

  const props = withDefaults(defineProps<{
    marqueeText?: string;
    title: string;
    description?: string;
    videoMobile: {
      light: Video;
      dark: Video
    };
    videoDesktop: {
      light: Video;
      dark: Video
    };
    link: string;
  }>(), {
    marqueeText: undefined,
    description: undefined,
  })

  const { $theme } = useNuxtApp()

  const computedVideoData = computed(() => {
    return isMobile.value ? props.videoMobile[$theme.currentTheme.value] : props.videoDesktop[$theme.currentTheme.value]
  })
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiMedia' as *;

  .main-start-screen__marquee {
    display: flex;
    padding: 12px 0;
    column-gap: 16px;
    background-color: var(--bg-secondary-color);

    &--tag {
      align-items: center;
      width: fit-content;
      margin-bottom: 32px;
      padding: 10px 12px 10px 6px;
      border-radius: 10px;
      column-gap: unset;
      background-color: var(--bg-secondary-color);

      @include media(xxl) {
        margin-top: 62px;
      }
    }
  }

  .main-start-screen__marquee-icon {
    margin-right: 2px;
    color: var(--accent-success-color);
  }

  .main-start-screen__content {
    @include media(md) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @include media(md-max) {
      gap: 8px 28px;
      margin-top: 40px;
    }

    @include media(lg) {
      grid-column-gap: 32px;
      grid-template-columns: 1fr 448px;
      align-items: center;
    }

    @include media(xl) {
      grid-column-gap: 32px;
      grid-template-columns: 1fr 496px;
    }

    @include media(xxl) {
      grid-template-columns: 1fr 672px;
    }
  }

  .main-start-screen__text {
    @include media(md, md-max) {
      grid-row: 1/3;
      align-self: center;
    }

    @include media(lg) {
      grid-row: 2/3;
      margin-bottom: 40px;
    }

    &:first-child {
      @include media(lg) {
        margin-top: 72px;
      }
    }
  }

  .main-start-screen__title {
    margin-bottom: 12px;

    @include media(sm-max) {
      text-align: center;
    }

    @include media(lg) {
      margin-bottom: 24px;
    }
  }

  .main-start-screen__description {
    margin-bottom: 10px;
    color: var(--text-secondary-color);

    @include media(sm-max) {
      text-align: center;
    }

    @include media(md) {
      margin-bottom: 0;
    }
  }

  .main-start-screen__video {
    border-radius: 12px;
    overflow: hidden;

    @include media(sm-max) {
      min-height: 216px;
      margin-bottom: 20px;
      aspect-ratio: 4 / 3.1;
    }

    @include media(md) {
      grid-row: 1/3;
      grid-column: 2/3;
      height: 258px;
    }

    @include media(lg) {
      grid-row: 1/4;
      height: 335px;
    }

    @include media(xl) {
      grid-row: 1/5;
      height: 370px;
    }

    @include media(xxl) {
      height: 496px;
    }
  }

  .main-start-screen__link {
    @include media(md, md-max) {
      grid-column: 1/-1;
    }

    @include media(lg) {
      grid-row: 3/4;
      justify-self: start;
    }
  }
</style>
