<template>
  <div class="main-bar">
    <p class="main-bar__desc t t--3">
      <span>Присоединяйтесь бесплатно</span> и&nbsp;учитесь у&nbsp;ведущих экспертов не&nbsp;выходя из&nbsp;дома
    </p>

    <UiIconButtonLink
      label="Войти"
      icon="basic/ui-arrow-right"
      size="big"
      :link="link"
      theme="quinary"
      class="main-bar__link main-bar__link--md-max"
      @click="handleClickLogin"
    />

    <UiButtonLink
      size="extra-big"
      :link="link"
      theme="quinary"
      class="main-bar__link main-bar__link--lg"
      @click="handleClickLogin"
    >
      Войти
    </UiButtonLink>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import UiButtonLink from '@academica-box/components/Links/UiButtonLink/UiButtonLink.vue'
  import UiIconButtonLink from '@academica-box/components/Links/UiIconButtonLink/UiIconButtonLink.vue'
  import { ActionsEnum, CategoryEnum } from '@academica-box/analytics/events/index'

  const { $analytics } = useNuxtApp()

  const config = useRuntimeConfig()
  const link = ref(`${config.public.passportDomain}/login/`)

  const handleClickLogin = () => {
    $analytics?.push({
      category: CategoryEnum.Home,
      action: ActionsEnum.click_login_button,
    })
  }
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiMedia/index' as *;

  .main-bar {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 10px;
    background-color: var(--bg-accent-color);

    @include media(360) {
      grid-column-gap: 32px;
    }

    @include media(lg) {
      padding: 6px 6px 6px 24px;
    }
  }

  .main-bar__desc {
    margin: 0;
    color: var(--accent-always-white-color);

    span {
      color: var(--accent-sale-color);
    }
  }

  .main-bar__link {
    &--md-max {
      @include media(lg) {
        display: none;
      }
    }

    &--lg {
      @include media(md-max) {
        display: none;
      }
    }
  }
</style>
